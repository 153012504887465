:root {
  margin: 0px;
  padding: 0px;
  --color1: #393e46;
  --color2: #f7f7f7;
  --color3: #6d9886;
  --color4: #ffe814;
  --color1rgb: 57, 62, 70; /* #393e46 */
  --color2rgb: 247, 247, 247; /* #f7f7f7 */
  --color3rgb: 109, 152, 134; /* #6d9886 */
  --color4rgb: 255, 232, 20; /* #ffe814 */
}

.body{
    background-color: var(--color2);
}

* {
  transition: all 0.2s ease;
}

.about--sec02 {
    width: 90%;
    margin: auto;
    padding-bottom: 6vh;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding-top: 6vh;
}

.about--sec02Content{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.about--title{
    color: var(--color3);
    font-size: 1.5rem;
}

.about--desc{
    color: var(--color1);
    font-weight: 300;
    text-align: justify;
}

.about--list{
    width: 80%;
    align-self: center;
    color: var(--color1);
    font-weight: 300;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.about--center{
    align-self: center;
    color: var(--color2);
}

.about--img{
    width: 90%;
    align-self: center;
}

.about--para{
    color: var(--color1);
    font-weight: 300;
    text-align: justify;
    width: 90%;
    align-self: center;
    color: var(--color2);
}

.about--sec03 {
    width: 100%;
    margin: auto;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding-top: 5vh;
    background-image: url("../../../public/_resources/img/aboutSec3.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.about--vmRow{
    width: 80%;
    align-self: center;
    display: flex;
    flex-direction: column;
    row-gap:1rem;
}

.about--vmRow p{
    width: 100%;
}

.about--sec04{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 3vh;
    padding-bottom: 3vh;
}

.about--sec04Title{
    width: 100%;
    align-self: center;
    color: var(--color1);
}

.about--para{
    width: 80%;
}

.about--sec04Para{
    color: var(--color1);
    margin-top: 1vh;
    width: 100%;
    align-self: center;
}

.about--sec04content{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.about--link{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1rem;
    column-gap: 1%;
    text-decoration: none;
    color: #393e46;
    font-weight: 900;
    padding-left: 3%;
    margin-top: 1vh;
}

.about--linkImg{
    height: 1rem;
}

.about--sec04Img{
    display: none;
}

@media screen and (min-width: 540px) {
    .about--sec02{
        flex-direction: row;
        justify-content: space-evenly;
        gap: 2%;
    }

    .about--sec03{
        flex-direction: row;
        justify-content: space-evenly;
        /* padding-left: 5%; */
    }

    .about--sec04{
        flex-direction: row;
        justify-content: center;
    }

    .about--sec02Content{
        width: 50%;
    }

    .about--vmRow{
        width: 35%;
    }

    .about--img{
        width: 30%;
        align-self: first baseline;
        height: 100%;
        aspect-ratio: 1;
    }

    .about--list{
        gap: 0.3rem;
    }

    .about--center{
        margin-top: 0.2vh;
        margin-bottom: 0.2vh;
    }

    .about--para{
        width: 60%;
    }

    .about--sec04content{
        width: 30%;
    }

    .about--sec04Img{
        display: block;
        width: 15%;
        aspect-ratio: 1;
        object-fit: cover;
        border-radius: 0.4rem;
    }
}
