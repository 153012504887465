:root {
  --color1: #393e46;
  --color2: #f7f7f7;
  --color3: #6d9886;
  --color4: #ffe814;
  --color1rgb: 57, 62, 70; /* #393e46 */
  --color2rgb: 247, 247, 247; /* #f7f7f7 */
  --color3rgb: 109, 152, 134; /* #6d9886 */
  --color4rgb: 255, 232, 20; /* #ffe814 */
}

.card {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-box-shadow: 0px 6px 16px 1px rgba(156, 156, 156, 1);
  -moz-box-shadow: 0px 6px 16px 1px rgba(156, 156, 156, 1);
  box-shadow: 0px 6px 16px 1px rgba(156, 156, 156, 1);
  background-color: var(--color2);
  padding-bottom: 5%;
  margin-top: 2%;
  margin-bottom: 2%;
  transition: all 0.2s ease;
}

.card:hover{
  transform: scale(1.1);
}

.card--imgDiv {
  width: 100%;
  aspect-ratio: 1.38;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0.3rem 0.3rem 0px 0px;
}

.card--contentDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card--title {
  width: 90%;
  color: var(--color1);
  font-weight: 500;
  text-align: left;
  margin: 0;
  line-height: 1.5vh;
  margin-top: 7%;
  text-transform: uppercase;
}

.card--desc {
  width: 90%;
  color: rgba(var(--color1rgb), 0.7);
  margin: 0;
  height: 8vh;
  line-height: 2vh;
  margin-top: 4%;
  text-align: justify;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.card--readMore {
  width: 89%;
  margin-top: 3%;
  text-decoration: none;
  color: var(--color3);
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card--moreImg {
  height: 2vh;
}

@media screen and (min-width: 540px) and (max-width: 950px) {
  .card{
    width: 30%;
    padding-bottom: 1%;
  }

  .card--desc{
    font-size: 1rem;
    height: 6vh;
    line-height: 1.5vh;
  }
}

@media screen and (min-width: 950px) {
  .card{
    width: 20%;
    padding-bottom: 1%;
  }

  .card--desc{
    font-size: 0.7rem;
    height: 8vh;
    line-height: 2vh;
  }
}