:root {
    margin: 0px;
    padding: 0px;
    font-family: "Inter";
    --color1: #393e46;
    --color2: #f7f7f7;
    --color3: #6d9886;
    --color4: #ffe814;
    --color1rgb: 57, 62, 70; /* #393e46 */
    --color2rgb: 247, 247, 247; /* #f7f7f7 */
    --color3rgb: 109, 152, 134; /* #6d9886 */
    --color4rgb: 255, 232, 20; /* #ffe814 */
  }

.footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color1);
    background-image: linear-gradient(#232528 0%, #393E46 100%);
    flex-wrap: wrap;
}

.footer--column{
    width: 80%;
    display: flex;
    flex-direction: column;
    padding-top: 3vh;
}

.footer--heading{
    font-size: 1.3rem;
    color: var(--color2);
    margin-bottom: 2vh;
    font-weight: 500;
    margin: 0;
}

.footer--subHeading{
    font-size: 1.3rem;
    color: var(--color2);
    margin-bottom: 2vh;
    font-weight: 500;
    margin: 0;
}

.footer--navLink{
    text-decoration: none;
    color: var(--color2);
    margin-top: 0.1vh;
    margin-bottom: 0.1vh;
    font-weight: 200;
    margin-left: 4%;
}

.footer--logo{
    width: 50%;
    margin-top: 8%;
    align-self: center;
}

.footer--smRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3%;
    margin-top: 3vh;
}

.footer--smIconDiv{
    width: 15%;
    aspect-ratio: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px var(--color2) solid;
    border-radius: 50%;
}

.footer--iconLink{
    width: 50%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer--smIcon{
    width: 100%;
}

.footer--span{
    color: var(--color2);
    margin-top: 0.1vh;
    margin-bottom: 0.1vh;
    font-weight: 200;
    margin-left: 4%;
}

.footer--line{
    width: 90%;
    border-top: 2px solid rgba(var(--color2rgb), 0.6);
    margin-bottom: 1vh;
}

.footer--copyright{
    color: var(--color2);
    font-size: 0.8rem;
    font-weight: 200;
    margin-top: 0.2vh;
    margin-bottom: 1vh;
}

@media screen and (min-width: 540px) {
    .footer{
        flex-direction: row;
        align-items: first baseline;
        justify-content: center;
        padding-top: 2vh;
    }

    .footer--column{
        width: 20%;
        /* border: 1px solid white; */
        margin-left: 7%;
        margin-right: 7%;
    }

    #footerColumn1{
        margin-left: 15%;
    }

    #footerColumn2{
        margin-left: -5%;
    }

    .footer--heading{
        text-align: center;
    }

    .footer--subHeading{
        font-size: 1.1rem;
    }

    .footer--navLink{
        margin-top: 0.5vh;
        margin-bottom: 0.5vh;
        margin-left: 0;
    }

    .footer--line{
        width: 80%;
    }

    .footer--copyright{
        width: 100%;
        text-align: center;
    }

    #heading1{
        align-self: first baseline;
    }

    #heading2{
        align-self: first baseline;
        margin-bottom: 3vh;
    }
}