:root {
    margin: 0px;
    padding: 0px;
    font-family: "Inter";
    --color1: #393e46;
    --color2: #f7f7f7;
    --color3: #6d9886;
    --color4: #ffe814;
    --color1rgb: 57, 62, 70; /* #393e46 */
    --color2rgb: 247, 247, 247; /* #f7f7f7 */
    --color3rgb: 109, 152, 134; /* #6d9886 */
    --color4rgb: 255, 232, 20; /* #ffe814 */
  }

.rvCard{
    width: 80%;
    aspect-ratio: 0.8;
    background-color: var(--color2);
    border-radius: 1rem;
    margin-top: 2%;
    margin-bottom: 2%;
    border: 0.11rem rgba(var(--color1rgb),0.8) solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgba(var(--color1rgb),1);
}

.rvCard--quote{
    align-self: first baseline;
    height: 5vh;
    margin-top: 10%;
    margin-left: 10%;
}

.rvCard--para{
    width: 80%;
    margin-top: 3vh;
    text-align: justify;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}

.rvCard--name{
    width: 70%;
    text-align: right;
    margin-top: 2vh;
    font-weight: bold;
}

@media screen and (min-width: 540px) and (max-width: 950px) {
    .rvCard{
        width: 31%;
        aspect-ratio: 0.8;
        font-size: 0.9rem;
    }
}

@media screen and (min-width: 950px) {
    .rvCard{
        width: 20%;
        aspect-ratio: 0.9;
        border: none;
        font-size: 0.8rem;
    }
}