@font-face {
  font-family: "Inter";
  src: url("_resources/fonts/Inter.ttf") format("truetype");
}

@font-face {
  font-family: "Aladin";
  src: url("_resources/fonts/Aladin.ttf") format("truetype");
}

*{
    font-family: "Inter";
}
