:root {
  margin: 0px;
  padding: 0px;
  --color1: #393e46;
  --color2: #f7f7f7;
  --color3: #6d9886;
  --color4: #ffe814;
  --color1rgb: 57, 62, 70; /* #393e46 */
  --color2rgb: 247, 247, 247; /* #f7f7f7 */
  --color3rgb: 109, 152, 134; /* #6d9886 */
  --color4rgb: 255, 232, 20; /* #ffe814 */
}

* {
  transition: all 0.2s ease;
}

body {
  width: 100vw;
  height: 100vh;
  background-color: var(--color2);
}

/*#region sec2*/

.home--sec2 {
  width: 96%;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 4vh;
  padding-bottom: 4vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home--sec2div1 {
  width: 92%;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home--welcome {
  width: 100%;
}

.home--sec2div2 {
  width: 96%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home--welcomeTitle {
  width: 90%;
  font-size: 1.5rem;
  font-weight: 800;
  color: var(--color3);
  text-align: left;
  margin: 0;
}

.home--welcomePara {
  width: 90%;
  margin: 0;
  margin-top: 2%;
  margin-bottom: 3%;
  text-align: justify;
}

.home--welcomeImgs {
  display: none;
}

@media screen and (min-width: 540px) and (max-width: 950px) {
  .home--sec2 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .home--sec2div1 {
    width: 60%;
    background-position: top;
  }

  .home--sec2div2 {
    width: 50%;
  }

  .home--sec2div2 {
    width: 50%;
  }

  .home--welcome {
    width: 70%;
  }
}

@media screen and (min-width: 950px) {
  .home--sec2 {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .home--sec2div1 {
    width: 50%;
  }

  .home--sec2div2 {
    width: 50%;
    justify-content: space-between;
  }

  .home--welcome {
    width: 80%;
  }

  .home--welcomeImgs {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 1%;
  }

  .home--welcomeImg {
    width: 40%;
    aspect-ratio: 1;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

/*#endregion*/

/*#region sec3*/

.home--sec3 {
  width: 90%;
  background-image: url("../../_resources/img/homeSec3.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 5%;
  padding-top: 3vh;
}

.home--sec3ContentDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.home--sec3Title {
  font-family: "Aladin";
  color: var(--color2);
  width: 95%;
}

.home--sec3List {
  list-style: none;
}

.home--sec3Para {
  text-align: justify;
  color: var(--color2);
  font-size: 1rem;
  width: 95%;
  margin: 0;
}

.home--sec3ListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5%;
  padding-top: 1%;
  padding-bottom: 1%;
}

.home--sec3ListItemImg {
  width: 5%;
}

.home--sec3ListItemText {
  color: var(--color2);
  font-size: 1rem;
}

.home--sec3Btn {
  text-decoration: none;
  width: 40%;
  padding: 4%;
  background-color: var(--color1);
  color: var(--color2);
  font-weight: 700;
  border-radius: 0.5rem;
  text-align: center;
  margin-bottom: 2%;
}

.home--sec3ImgDiv {
  width: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.home--sec3Img1 {
  width: 60%;
  aspect-ratio: 1;
}

.home--sec3Img2 {
  width: 60%;
  aspect-ratio: 1;
  margin-left: 40%;
  margin-top: -20%;
}

@media screen and (min-width: 540px) and (max-width: 950px) {
  .home--sec3List {
    align-self: first baseline;
  }

  .home--sec3Btn {
    align-self: first baseline;
  }
}

@media screen and (min-width: 950px) {
  .home--sec3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  .home--sec3ContentDiv {
    width: 60%;
  }

  .home--sec3ImgDiv {
    width: 35%;
    margin: 0;
  }

  .home--sec3Title0 {
    /* margin-top: 10vh; */
  }

  .home--sec3List {
    align-self: first baseline;
  }

  .home--sec3Btn {
    align-self: first baseline;
    width: 20%;
    padding: 2%;
    margin-bottom: 0%;
    margin-left: 3%;
  }
}

/*#endregion*/

/*#region Sec4*/

.home--sec4 {
  width: 98%;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 2vh;
  padding-bottom: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home--sec4Title {
  font-size: 2rem;
  font-weight: 300;
  color: var(--color3);
  margin-top: 3vh;
  margin-bottom: 1vh;
  text-transform: uppercase;
}

.home--sec4Desc {
  font-size: 1rem;
  font-weight: 200;
  color: var(--color1);
  width: 90%;
  text-align: center;
  margin-bottom: 2vh;
}

.home--sec4CardWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 2%;
}

.home--moreBtn {
  width: 45%;
  text-align: center;
  padding: 5%;
  text-decoration: none;
  font-weight: 600;
  color: var(--color2);
  background-color: var(--color1);
  border-radius: 0.5rem;
  margin-top: 2%;
  margin-bottom: 2%;
}

.home--moreBtn:hover {
  transform: scale(1.04);
}

@media screen and (min-width: 540px) and (max-width: 950px) {
  .home--moreBtn {
    width: 20%;
    padding: 3%;
  }
}

@media screen and (min-width: 950px) {
  .home--moreBtn {
    width: 16%;
    padding: 0;
    padding-top: 1%;
    padding-bottom: 1%;
  }
}

/*#endregion*/

/*#region Sec5*/

.home--sec5 {
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 6vh;
}

.home--sec5Title {
  font-size: 2rem;
  font-weight: 300;
  color: var(--color3);
  margin-bottom: 5vh;
  text-transform: uppercase;
}

/*#endregion*/

/*#region Sec6*/
.home--sec6 {
  width: 100%;
  background-image: url("../../../public/_resources/img/sec6bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.home--sec6Content {
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: linear-gradient(180deg,rgba(var(--color2rgb),1) 0%,rgba(var(--color2rgb),1) 2%, rgba(var(--color2rgb), 0.1) 40%);
  padding: 5%;
}

.home--reviewRow{
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10vh;
  flex-wrap: wrap;
  overflow: hidden;
  gap: 2%;
}

/*#endregion*/
