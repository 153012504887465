:root {
  margin: 0px;
  padding: 0px;
  --color1: #393e46;
  --color2: #f7f7f7;
  --color3: #6d9886;
  --color4: #ffe814;
  --color1rgb: 57, 62, 70; /* #393e46 */
  --color2rgb: 247, 247, 247; /* #f7f7f7 */
  --color3rgb: 109, 152, 134; /* #6d9886 */
  --color4rgb: 255, 232, 20; /* #ffe814 */
}

* {
  transition: all 0.2s ease-in-out;
}

body {
  background-color: var(--color2);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.contact--sec01 {
  width: 100%;
  padding-top: 3vh;
  padding-bottom: 3vh;
  display: flex;
  flex-direction: column;
  padding-left: 5%;
}

.contact--sec01Title {
  font-size: 2rem;
  color: var(--color1);
  margin-bottom: 2vh;
}

.contact--sec01Desc {
  color: var(--color1);
  font-weight: 300;
  max-width: 80%;
  text-align: justify;
}

.contact--sec01Content {
  display: flex;
  flex-direction: column;
  align-items: first baseline;
  width: 90%;
}

.contact--labelsRow {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact--label {
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  margin-top: 1%;
  margin-bottom: 1%;
  /* border: 2px solid rgba(var(--color1rgb), 0.5); */
  color: var(--color1);
  border-radius: 1rem;
}

.contact--labelImg {
  width: 20%;
}

.contact--labelContent {
  min-width: 50%;
}

.contact--labelName {
  font-weight: 800;
}

.contact--smDiv {
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 5%;
  margin-top: 2vh;
}

.contact--smLink {
  width: 30%;
  aspect-ratio: 1;
  border: 1px solid rgba(var(--color1rgb), 0.8);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact--smIcon {
  width: 60%;
  object-fit: contain;
}

.contact--img {
  width: 90%;
  margin-top: 2vh;
}

.contact--imgDiv{
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact--sec01Title2{
  color: var(--color1);
  padding-left: 4%;
  padding-top: 2%;
}

@media screen and (min-width: 540px) {
  .contact--labelsRow {
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 1vh;
    margin-bottom: 1vh;
  }

  .contact--label {
    width: 45%;
  }

  .contact--sec01Content {
    width: 90%;
  }

  .contact--smDiv {
    width: 30%;
    align-self: first baseline;
  }
}

@media screen and (min-width: 950px) {
  .contact--sec01 {
    width: 100%;
    flex-direction: row;
  }

  .contact--labelsRow {
    width: 80%;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .contact--imgDiv{
    height: 100%;
  }

  .contact--img {
    width: 70%;
    margin-top: 0;
  }
}
