:root {
  margin: 0px;
  padding: 0px;
  font-family: "Inter";
  --color1: #393e46;
  --color2: #f7f7f7;
  --color3: #6d9886;
  --color4: #ffe814;
  --color1rgb: 57, 62, 70; /* #393e46 */
  --color2rgb: 247, 247, 247; /* #f7f7f7 */
  --color3rgb: 109, 152, 134; /* #6d9886 */
  --color4rgb: 255, 232, 20; /* #ffe814 */
}

@keyframes fadeUpIn {
  from{
    opacity: 0;
    transform: translateY(-30%);
  }
  to{
    transform: translateY(0);
    opacity: 1;
    z-index: 0;
  }
}

.hero {
  width: 98%;
  height: 95vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-bottom: 5vh;
  padding-left: 2%;
}

.hero--row1 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  animation: fadeUpIn 1s forwards;
}

.hero--heading {
  font-size: 3rem;
  margin: 0px;
  width: 90%;
  color: var(--color2);
  animation: fadeUpIn;
}

.hero--endDiv {
  font-size: 2.5rem;
  background-color: var(--color4);
}

.hero--hrDiv {
  width: 88%;
  height: 2px;
  margin-top: 2%;
  margin-left: 2%;
  background-color: rgba(var(--color2rgb), 0.7);
}

.hero--row2 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  animation: fadeUpIn 1s forwards;
}

.hero--subHeading {
  font-size: 1rem;
  margin: 0;
  margin-top: 1vh;
  font-weight: 200;
  width: 85%;
  color: var(--color2);
}

@media screen and (min-width: 540px) and (max-width: 950px) {
  .hero--heading {
    font-size: 3rem;
    width: 75%;
  }

  .hero--hrDiv{
    width: 74%;
  }

  .hero--subHeading {
    font-size: 1rem;
  }
}

@media screen and (min-width: 950px) {
  .hero--heading {
    font-size: 3rem;
    width: 50%;
  }

  .hero--hrDiv{
    width: 50%;
  }

  .hero--subHeading {
    font-size: 1.2rem;
  }
}
