@font-face {
  font-family: "Inter";
  src: url("../../_resources/fonts/Inter.ttf") format("truetype");
}

@keyframes fadeUpIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
    z-index: 100;
  }
}

:root {
  margin: 0px;
  padding: 0px;
  --color1: #393e46;
  --color2: #f7f7f7;
  --color3: #6d9886;
  --color4: #ffe814;
  --color1rgb: 57, 62, 70;
  --color2rgb: 247, 247, 247;
  --color3rgb: 109, 152, 134;
  --color4rgb: 255, 232, 20;
}

body {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  background-color: var(--color2);
  font-family: "Inter";
  font-weight: 400;
}

.header--container {
  width: 100%;
  position: fixed;
  z-index: 99;
}

.header--logo {
  height: 9vh;
  max-width: 25%;
  object-fit: contain;
}

.header--navbar {
  width: 100%;
  min-height: 13vh;
  position: fixed;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  z-index: 999;
  padding-left: 5%;
  padding-right: 5%;
  transition: all 0.2s ease;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeUpIn 1s forwards 0.6s;
}

.header--navbar.scrolled {
  background-color: rgba(var(--color3rgb), 0.95);
}

.header--navItems {
  display: none;
}

.header--toggleBtn {
  width: 5vh;
  height: 5vh;
  border-radius: 0.3rem;
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  margin-left: auto;
  margin-right: 8%;
  row-gap: 15%;
}

.header--toggleBtnRow {
  width: 100%;
  height: 6%;
  background-color: var(--color2);
}

.header--lastToggleBtnRow {
  width: 60%;
  height: 6%;
  background-color: var(--color2);
  transition: width 0.2s ease;
}

.header--lastToggleBtnRow.show {
  width: 100%;
}

.header--sidebar {
  background-color: var(--color1);
  height: 100vh;
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: left 0.3s ease;
  width: 60vw;
  left: -60vw;
}

.header--sidebarContent {
  padding-left: 5%;
}

.header--sidebar.show {
  left: 0vw;
}

.header--sidebarItems {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-top: 6%;
}

.header--sidebarItem {
  text-decoration: none;
  color: var(--color2);
  text-align: center;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  width: 90%;
  border: 1px solid var(--color2);
  opacity: 0.8;
  border-radius: 0.2rem;
  font-weight: bold;
  margin-top: 2%;
}

.header--sidebarLogo {
  width: 70%;
  margin-top: 8%;
}

.header--closeBtn {
  width: 7%;
  position: absolute;
  top: 3%;
  left: 85%;
}

@media screen and (min-width: 540px) {
  .header--sidebar {
    width: 40vw;
    left: -40vw;
  }
}

@media screen and (min-width: 950px) {
  .header--sidebar {
    display: none;
  }

  .header--toggleBtn {
    display: none;
  }

  .header--navItems {
    display: flex;
    flex-direction: row;
    width: 50%;
    align-items: center;
    list-style: none;
    margin-left: auto;
    margin-right: 10%;
    justify-content: space-between;
  }

  .header--navItem {
    position: relative;
    text-decoration: none;
    color: var(--color2);
  }

  .header--navItem::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -2px;
    width: 0;
    height: 2px;
    background-color: currentColor;
    transition: width 0.3s ease, left 0.3s ease;
  }

  .header--navItem:hover::after {
    width: 100%;
    left: 0;
  }

  .header--navItemSelected {
    position: relative;
    color: var(--color4);
    text-decoration: none;
    font-weight: 600;
  }

  .header--navItemSelected::before {
    content: "";
    height: 0.1vh;
    width: 120%;
    left: -10%;
    position: absolute;
    bottom: -0.5vh;
    background-color: var(--color4);
  }
}
