:root {
  margin: 0px;
  padding: 0px;
  --color1: #393e46;
  --color2: #f7f7f7;
  --color3: #6d9886;
  --color4: #ffe814;
  --color1rgb: 57, 62, 70; /* #393e46 */
  --color2rgb: 247, 247, 247; /* #f7f7f7 */
  --color3rgb: 109, 152, 134; /* #6d9886 */
  --color4rgb: 255, 232, 20; /* #ffe814 */
}

*{
  transition: all 2s;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 2%;
}

.form--col {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-top: 0.8vh;
  margin-bottom: 0.8vh;
  row-gap: 1vh;
}

.form--label {
  color: var(--color1);
}

.form--input {
  width: 98%;
  height: 5vh;
  border: 0.15rem solid rgba(var(--color1rgb), 0.6);
  border-radius: 0.2rem;
  padding-left: 2%;
}

.form--textarea {
  height: 15vh;
  border: 0.15rem solid rgba(var(--color1rgb), 0.6);
  width: 96%;
  padding-left: 2%;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-right: 2%;
}

.form--btn {
  width: 70%;
  padding-top: 1vh;
  padding-bottom: 1vh;
  border: none;
  background-color: var(--color1);
  font-weight: 600;
  color: var(--color2);
  border-radius: 0.3rem;
  margin-top: 2%;
}

.form--btn:hover {
  cursor: pointer;
  scale: 1.1;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, alpha);
}

.form--message{
  width: 90%;
  margin-top: 4%;
  margin-bottom: 1.8%;
  color: rgb(197, 51, 51);
  display: none;
  opacity: 0;
}

.form--message.show{
  display: block;
  opacity: 1;
}

@media screen and (min-width: 540px) {
  .form--col {
    width: 45%;
  }

  #col5 {
    width: 92%;
    column-gap: 0;
  }
}
