*{
    padding: 0;
    margin: 0;
}

.gallery--wrapper{
    max-width: 100%;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    gap: 0.5%;
}

.gallery--column{
    display: flex;
    flex-direction: column;
    gap: 0.5%;
}

.gallery--photo img{
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .gallery--wrapper{
        flex-direction: column;
    }
}