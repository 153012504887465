:root {
  margin: 0px;
  padding: 0px;
  font-family: "Inter";
  --color1: #393e46;
  --color2: #f7f7f7;
  --color3: #6d9886;
  --color4: #ffe814;
  --color5: #5b953e;
  --color1rgb: 57, 62, 70; /* #393e46 */
  --color2rgb: 247, 247, 247; /* #f7f7f7 */
  --color3rgb: 109, 152, 134; /* #6d9886 */
  --color4rgb: 255, 232, 20; /* #ffe814 */
}

.dest--sec01 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dest--cardWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dest--btnRow {
  width: 100%;
  margin-top: 5vh;
  margin-bottom: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: last baseline;
  gap: 2%;
  color: var(--color1);
}

.dest--paginationBtn {
  width: 10%;
  aspect-ratio: 1;
  border: 1px var(--color5) solid;
  border-radius: 50%;
  box-shadow: 0px 1px 5px 0px rgba(var(--color1rgb), 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color1);
}

.dest--paginationBtn:hover{
    background-color: rgba(var(--color1rgb), 0.2);
    cursor: pointer;
}

.dest--paginationBtn.active {
  background-color: var(--color5);
  color: var(--color2);
}

.dest--paginationBtnIcon {
  width: 50%;
}

#prevBtn{
    transform: rotateZ(180deg);
}

.dest--title{
    width: 90%;
    color: var(--color3);
    text-transform: uppercase;
    margin-top: 4vh;
    margin-bottom: 1vh;
}

.dest--desc{
    width: 90%;
    font-size: 0.9rem;
    color: rgba(var(--color1rgb),0.9);
    margin-bottom: 2vh;
    text-align: justify;
}

@media screen and (min-width: 540px) {
  .dest--cardWrapper {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 1%;
  }

  .dest--btnRow {
    width: 30%;
  }

  .dest--title{
    width: 60%;
    margin-left:9%;
    align-self: first baseline;
  }

  .dest--desc{
    width: 80%;
    margin-left: 9%;
    align-self: first baseline;
  }
}
